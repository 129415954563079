@import 'src/global/mixins.scss';

.enterpriseSearch {
  @include container;

  &.margin {
    margin-bottom: var(--offset-spacing);
  }

  .fixed {
    @include rounded-large;
    position: fixed;
    z-index: var(--global-z-index-search);
    box-shadow: 0 4px 7px 0 rgb(0 0 0 / 10%);
  }

  .cleanPromptWrapper {
    position: relative;
    top: var(--offset-spacing);
  }
}
