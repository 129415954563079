@import 'src/global/mixins.scss';

.sourcePopoverContent {
  @include flex-start;
  gap: var(--global-space-single);
  position: relative;
  padding: var(--global-space-single);

  .sourceDetails {
    @include container;
    max-width: 90%;
    gap: var(--global-space-single);
    color: var(--global-color-es-gray-dark);
    text-align: left;

    .header {
      display: flex;
      gap: var(--global-space-single);

      .title {
        font-size: 12px;
        font-weight: 600;
        color: var(--global-color-es-gray-dark);
      }

      .inactive {
        color: white;
        font-size: 10px;
        border-radius: 2px;
        background-color: var(--global-color-dark-gray);
        padding: calc(var(--global-space-half) / 2) var(--global-space-half);
        text-transform: capitalize;
      }
    }

    .subheader {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      color: var(--global-color-es-gray-light);
    }
  }
}
