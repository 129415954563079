@import 'src/global/mixins.scss';

$hint-background: rgb(216 241 255);

.hintMessage {
  @include flex-start;
  gap: var(--global-space-single);
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: $hint-background;
  padding: calc(3 * var(--global-space-half)) calc(8 * var(--global-space-half));

  .text {
    font-size: 14px;
  }

  .icon {
    height: 16px;
    width: 16px;
  }
}
