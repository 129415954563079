@mixin flex-horizontal {
  display: flex;
  align-items: center;
}

@mixin flex-center($direction: row) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: $direction;
}

@mixin flex-stretch {
  display: flex;
  align-items: stretch;
}

@mixin flex-start {
  display: flex;
  align-items: flex-start;
}

@mixin container ($gap: var(--global-space-double)) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin lines-limit($lines-amount) {
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines-amount;
  -webkit-box-orient: vertical;
}

@mixin ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin transition($transition-property, $duration: var(--global-transition-duration)) {
  transition: $transition-property $duration ease;
}

@mixin rounded-large {
  border-radius: 8px;
}

@mixin rounded {
  border-radius: 4px;
}

@mixin rounded-card {
  border-radius: 8px;
  border: 1px solid var(--global-color-gray-4);
  @include shadowed;
}

@mixin content-card {
  @include rounded-card;
  padding: var(--global-space-triple);
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
}

@mixin shadowed {
  box-shadow: 0 0.75rem 1.5rem rgba(7, 8, 71, 0.03);
}

@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
   animation-delay: $delay;
}

@mixin border-left($color) {
  border-radius: 8px;
  border-left: 5px solid;
  border-left-color: $color;
}

@mixin transparent-content {
  color: black;
  background-color: white;
  mix-blend-mode: screen;
}

@mixin label {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--global-color-dark-gray);
}

@mixin fade-in-animation($duration: var(--global-transition-duration)) {
  animation: fadeInAnimation $duration linear;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@keyframes loading-skeleton-blink-animation {
  0%,
  15%,
  100% {
    opacity: 0.3;
  }

  40%,
  90% {
    opacity: 1;
  }
}

@mixin loading-skeleton-blink {
  animation: loading-skeleton-blink-animation 2s infinite;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@mixin slide-upwards-animation {
  animation: slide-up 0.5s ease-out forwards;
}
@mixin slide-downwards-animation {
  animation: slide-up 0.5s ease-out backwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@mixin box-shadow-transition {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
