@import 'src/global/mixins.scss';

@mixin common-button-styles($font-size, $font-weight) {
  font-size: $font-size;
  font-weight: $font-weight;
  color: var(--global-color-es-gray-dark);
  padding: var(--global-space-half) var(--global-space-single);
  background-color: white;
}

.filterButton {
  @include common-button-styles(12px, 400);
  border: 1px solid var(--global-color-gray-3);
  transition:
    background-color var(--global-transition-duration) ease,
    border var(--global-transition-duration) ease;

  &.selected {
    background-color: var(--global-color-light-blue);

    &:hover {
      background-color: var(--global-color-gray-3);
    }
  }

  &.focused {
    border: 1px solid var(--global-color-dark-blue);
  }

  &:hover {
    background-color: var(--global-color-light-blue);
  }

  &:disabled {
    background-color: white;
    opacity: 0.5;

    &:hover {
      background-color: white;
    }
  }
}

.clearFilterButton {
  @include common-button-styles(10px, 600);
  letter-spacing: 0.8px;
  text-transform: uppercase;

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    background-color: white;
    opacity: 0.5;
  }
}
