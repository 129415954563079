@import 'src/global/mixins.scss';

$background-default: var(--global-color-transparent-white);
$background-hover: rgb(255 255 255 / 55%);

.suggestedQuestion {
  @include flex-horizontal;
  position: relative;
  overflow: hidden;
  background: $background-default;
  padding: var(--global-space-single) var(--global-space-double);
  border-radius: 0 20px 20px;
  text-align: start;
  font-size: 12px;
  min-height: 31px;

  &:hover {
    background: $background-hover;
  }

  &.blue {
    background: var(--global-color-light-blue);

    &:hover {
      background: var(--global-color-lighter-blue);
    }
  }
}
