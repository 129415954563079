@import 'src/global/mixins.scss';

.searchResultCard {
  padding-bottom: var(--global-space-quadruple);
  @include slide-upwards-animation;
  width: 100%;

  .searchResultContent {
    @include container;
    gap: var(--global-space-single);
    flex-direction: row;
    position: relative;

    // Putting a big max-height for the transition to work.
    max-height: 10000px;
    @include transition(max-height, 1s);

    .iconWrapper {
      width: 20px;
      flex-shrink: 0;
      padding: 0 2px;
    }

    .resultDetails {
      @include container(calc(var(--global-space-half) * 3));
      flex-grow: 1;

      .content {
        @include container(var(--global-space-triple));
        word-break: break-word;
        hyphens: auto;
      }
    }

    &.error {
      .iconWrapper {
        opacity: 0.3;
      }

      .resultDetails {
        .question {
          opacity: 0.3;
        }
      }
    }
  }

  /* stylelint-disable no-descending-specificity */

  &.collapsed {
    .searchResultContent {
      overflow-y: hidden;
      max-height: 75px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40px;
        background: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 100%);
        pointer-events: none;
      }
    }

    .resultDetails {
      .content {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }

  /* stylelint-enable no-descending-specificity */

  .showResultsButton {
    color: var(--global-color-blue);
    width: 100%;
    padding-top: 20px;
    transition: opacity var(--global-transition-duration) ease;

    &:hover {
      opacity: 0.5;
    }
  }
}
