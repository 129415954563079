@import 'src/global/mixins.scss';

.atomSearchMetadata {
  display: flex;
  align-items: center;
  gap: var(--global-space-half);

  .primaryInfo {
    font-size: 12px;
    font-weight: 500;
    color: var(--global-color-es-gray-dark);
  }

  .secondaryInfo {
    font-size: 10px;
    font-weight: 400;
    color: var(--global-color-gray-10);
  }
}
