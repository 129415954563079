@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

:root {
  --global-space-half: 4px;
  --global-space-single: 8px;
  --global-space-double: 16px;
  --global-space-triple: 24px;
  --global-space-quadruple: 32px;

  --global-transition-duration: 0.2s;

  --global-color-gray-1: #262D3D;
  --global-color-gray-2: #B1AFBC;
  --global-color-gray-3: #DFDDE4;
  --global-color-gray-4: #F1F0F5;
  --global-color-gray-5: #F2F5FA;
  --global-color-gray-6: #E9E8EC;
  --global-color-gray-7: #D9D9D9;
  --global-color-gray-8: #F8F8F8;
  --global-color-gray-9: #827AA3;
  --global-color-gray-10: #979797;
  --global-color-gray-11: #807666;

  // TODO: This is a temp solution until we establish the gray color palette
  --global-color-es-gray-dark: #555;
  --global-color-es-gray-light: #AEAEAE;
  --global-color-border-gray: color-mix(in srgb, var(--global-color-dark-gray) 40%, transparent);
  --global-color-background-gray: #F7F9FB;

  --global-color-hover-gray: #F1F0F555;
  --global-color-dark-blue: #070847;
  --global-color-dark-gray: #6B6680;
  --global-color-red: #E91E63;
  --global-color-green: #27D2B1;
  --global-color-yellow: #FFBF42;
  --global-color-transparent-red: rgb(234 67 53 / 50%);
  --global-color-transparent-white: rgb(255 255 255 / 40%);

  --global-color-gray: rgb(204, 204, 204);
  --global-color-lighter-gray: #F8F8FA;

  --global-color-blue-h: 202;
  --global-color-blue-s: 100%;
  --global-color-blue-l: 46%;
  --global-color-light-blue: #E9F7FF;
  --global-color-lighter-blue: #B4E3FF;
  --global-color-blue: hsl(var(--global-color-blue-h), var(--global-color-blue-s), var(--global-color-blue-l));

  --global-color-light-green: #E2FAE5;
  --global-color-light-red: #FAE2E2;

  --global-color-modal-overlay-rgb: 7 8 71;
  --global-color-modal-overlay: rgb(var(--global-color-modal-overlay-rgb) / 20%);

  --global-z-index-search: 10;
  --global-z-index-modal: 9;
  --global-z-index-loading-modal: 8;
  --global-z-index-dropdown: 7;
  --global-z-index-navbar: 6;
  --global-z-index-drawer: 5;
  --global-z-index-master-filters: 4;
  --global-z-index-right-panel: 4;
  --global-z-index-bottom-panel: 3;
  --global-z-index-loading-screen: 2;
  --global-z-index-content: 1;

  --global-search-width: 680px;
  --global-top-navbar-height: 64px;
  --global-left-panel-width: 200px;
  --global-left-navbar-width: 200px;
  --global-right-panel-width: 450px;
  --global-bottom-panel-height: 52px;
  --global-button-height: 34px;
  --global-chip-button-height: 25px;
}

body {
  margin: 0;
}

* {
  font-family: Inter, sans-serif;
}

a {
  text-decoration: none;
  color: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
}

.ReactModal__Overlay {
  z-index: var(--global-z-index-modal);
}
