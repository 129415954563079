@import 'src/global/mixins.scss';

.subtitle {
  display: flex;
  align-items: center;
  color: var(--global-color-gray-10);
  font-weight: 400;
  font-size: 8px;
  gap: var(--global-space-half);

  .truncatedText {
    @include ellipsis;

    &.channel {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      color: var(--global-color-es-gray-dark);
      font-size: 12px;
      font-weight: 600;
    }

    &.date {
      font-size: 10px;
    }
  }
}
