@import 'src/global/mixins.scss';

$border-radius: 8px;
$disabled-color: var(--global-color-gray-7);
$focused-color: var(--global-color-lighter-blue);

* {
  margin: 0;
  padding: 0;
}

.searchContainer {
  @include rounded-large;
  @include flex-center;
  gap: calc(4 * var(--global-space-single));
  width: var(--global-search-width);
  height: 100%;
  background-color: white;
  padding: var(--global-space-double);
  padding-right: calc(3 * var(--global-space-half));
  outline: 1px solid transparent;
  transition:
    background-color var(--global-transition-duration) ease,
    color var(--global-transition-duration) ease,
    outline var(--global-transition-duration) ease;

  &.disabled {
    background-color: var(--global-color-gray-8);
    color: $disabled-color;
    outline: 1px solid $disabled-color;
  }

  &.focused {
    outline: 3px solid $focused-color;
  }

  .searchInput {
    box-shadow: none;
    border: none;
    background-color: transparent;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: black;
    resize: none;
    @include transition(color);

    &::placeholder {
      font-weight: 400;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      color: $disabled-color;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.compact {
    padding:
      var(--global-space-single)
      calc(3 * var(--global-space-half))
      var(--global-space-single)
      calc(5 * var(--global-space-half));
    gap: calc(3 * var(--global-space-half));
    border: 1px solid rgb(107 102 128 / 20%);

    .searchInput {
      font-size: 12px;
    }
  }

  .actions {
    .searchButtonIcon {
      display: flex;
      align-self: center;
      color: black;
      @include transition(color);

      .clearIcon {
        width: 16px;
      }

      .searchIcon {
        @include transition(transform);
      }

      &:disabled {
        background: transparent;
        color: $disabled-color;
      }

      &:hover {
        opacity: 0.7;

        &:not(:disabled) {
          .searchIcon {
            transform: translateX(3px);
          }
        }
      }
    }
  }

  &.autoComplete {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    outline: 1px solid transparent;
    border: none;
    border-bottom: 1px solid $focused-color;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%);
  }
}
