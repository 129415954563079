@import 'src/global/mixins.scss';
@import 'src/screens/platform/AnalyticsScreen/widgets/chartStyle.module.scss';

.significanceTableWrapper {
  @include chart-wrapper;
  @include transition(height);
  height: 82px;

  &.emptyChart {
    height: 150px;
  }
}
