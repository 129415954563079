@import 'src/global/mixins.scss';

.atomSearchResult {
  @include flex-start;
  @include transition(background-color);
  gap: calc(var(--global-space-half) * 3);
  overflow: hidden;
  width: 100%;
  padding: calc(3 * var(--global-space-half));
  background-color: white;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid var(--global-color-gray-6);
  }

  &.transparent {
    background-color: transparent;

    &:hover {
      background-color: white;
    }
  }

  .content {
    @include container(var(--global-space-single));
    min-width: 500px;
    flex-grow: 1;
    height: 75px;

    .atomTitle {
      @include ellipsis;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      color: var(--global-color-es-gray-dark);
    }
  }

  .summarize {
    position: absolute;
    bottom: 20px;
    right: 12px;
    @include flex-center;
    gap: var(--global-space-half);
    margin-left: auto;
    overflow: hidden;
    color: var(--global-color-blue);
    opacity: 0;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    @include transition(opacity);

    &:hover {
      color: black;
    }

    .icon {
      width: 12px;
      height: 12px;
      transition: transform 0.2s ease-in-out;
    }

    .summarizeText {
      font-size: 12px;
      font-weight: 500;
      opacity: 0;
      white-space: nowrap;
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

      &.visible {
        opacity: 1;
      }
    }
  }

  .externalLink {
    @include transition(opacity);
    position: absolute;
    top: 12px;
    right: 12px;
    opacity: 0;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    color: var(--global-color-dark-gray);
  }

  &:hover {
    background-color: var(--global-color-light-blue);

    .externalLink {
      opacity: 1;
    }

    .summarize {
      opacity: 1;
    }
  }

  &.highlighted {
    background-color: var(--global-color-light-blue);

    &.transparent {
      background-color: white;
    }

    .summarize {
      color: var(--global-color-dark-blue);
      opacity: 1;
    }
  }
}

.tooltip {
  min-height: 110px;
  max-height: 300px;
  min-width: 525px;
  max-width: 525px;
  padding: var(--global-space-single);
  background-color: var(--global-color-gray-5);
}
