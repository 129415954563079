@import 'src/global/mixins.scss';

.errorMessage {
  @include flex-center;
  flex-direction: column;
  padding: calc(5 * var(--global-space-half)) 0;
  gap: var(--global-space-half);

  .emoji {
    font-size: 40px;
  }

  .subtitle {
    color: var(--global-color-dark-gray);
  }

  .callTActionButton {
    margin-top: var(--global-space-double);
  }
}
